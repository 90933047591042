*{
  @import: url(https://fonts.googleapis.com/css?family=Roboto+Condensed);
}

.button-2 {
color: #fff;
border: 2px solid #003c63;
background-image: -webkit-linear-gradient(30deg, #003c63 50%, transparent 50%);
background-image: linear-gradient(30deg, #003c63 50%, transparent 50%);
background-size: 500px;
background-repeat: no-repeat;
background-position: 0%;
-webkit-transition: background 400ms ease-in-out;
transition: background 400ms ease-in-out;
}
.button-2:hover {
background-position: 100%;
color: #003c63;
}

/* =========== footer =========== */

.big:hover{
letter-spacing: 5px;
size: bold;
}

/* ============== Product Page ============ */

.productItem:hover{
padding-left: 10px;
transition: 300ms ease-in-out;
color: white;
}


.products{
background-color: #77b2d6;
text-decoration: none;
color: black;
border-radius: 5px;
margin-bottom: 5px;
padding: 10px;
display: flex;
justify-content: start;
align-items: center;
}

.products:hover{
background-color: #003c63;
color: white;
}


/* ================= branch page ====== */


.location {
  background-color: transparent; /* Make the button background transparent */
  border: none; /* Remove the button border */
  color: red; /* Set the text color to red or any color you like */
  /* font-size: 24px; Adjust the font size as needed */
  /* text-transform: uppercase; Convert text to uppercase */
  /* font-weight: bold; Make the text bold */
  animation: colorChange 1s infinite; /* Apply an animation to change the text color */
  border: none;
  /* border-bottom: 1px solid white; */
  /* margin-top: 13px;
  font-size: 18px; */
}

@keyframes colorChange {
  0% {
    color: rgb(247, 244, 244);
  }
  25% {
    color: white;
  }
  50% {
    color: rgb(42, 115, 224);
  }
 
}

/* //=========================================// */

.specialityOfProduct{
  background-color: rgb(224, 224, 224);
  color: black;
  border: 0px;
}
.specialityOfProduct:hover{
  background-color: #c0d8e8;
  color: black;
}