*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

/* ============ navbar ============ */

.navItem:hover{
  border-bottom: 2px solid #003c63;
}

/* =========== login form ================= */
.login-container{
    /* height: 100vh; */
    /* justify-content: center; */
    display: flex;
    /* align-items:center;     */
  }
  .login-form{
    margin: auto;
    /* width: 25em; */
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 30px 70px;
    align-items: center;
    justify-content: space-around;
    color: white;  
  }
  
  h1 {
    text-align: center;
    padding-bottom: 30px;
  }
  
  #input,#input1{
    width: 100%;
    padding: 13px;
    font-size: 16px;
    margin-bottom: 20px;
    background-color: transparent;
    border: none ;
    border-bottom: 1px solid white;
    color: white;
  }
  #input::placeholder{
    color: white;
    background-color: transparent;
  }
  #input1::placeholder{
    background-color: transparent;
    color: white;
  }
  
  .login-button{
    display: flex;
    justify-content: center;
    
  }
  .login-btn:hover{
    color: black;
    background-color: rgb(233, 224, 227);
  }
  .login-btn{
    padding: 10px 15px;
    font-size: 16px;
    color:white;
    background-color: transparent;
    border:1px solid white;
    margin-top: 17px;
    cursor: pointer;
    border-radius: 5px;
  }




  /* =========== footer =========== */

  .big:hover{
    letter-spacing: 5px;
    size: bold;
  }



  /* ========= buttons style =============  */

  *{
    @import: url(https://fonts.googleapis.com/css?family=Roboto+Condensed);
}

.adminButton {
  color: #fff;
  border: 2px solid #003c63;
  background-image: -webkit-linear-gradient(30deg, #003c63 50%, transparent 50%);
  background-image: linear-gradient(30deg, #003c63 50%, transparent 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}
.adminButton:hover {
  background-position: 100%;
  color: #003c63;
}

.adminButtonRed {
  color: #fff;
  border: 2px solid red;
  background-image: -webkit-linear-gradient(30deg, red 50%, transparent 50%);
  background-image: linear-gradient(30deg, red 50%, transparent 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}
.adminButtonRed:hover {
  background-position: 100%;
  color: red;
}

.adminButtonOrange {
  color: #fff;
  border: 2px solid orange;
  background-image: -webkit-linear-gradient(30deg, orange 50%, transparent 50%);
  background-image: linear-gradient(30deg, orange 50%, transparent 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}
.adminButtonOrange:hover {
  background-position: 100%;
  color: orange;
}

.adminButtonGreen {
  color: #fff;
  border: 2px solid green;
  background-image: -webkit-linear-gradient(30deg, green 50%, transparent 50%);
  background-image: linear-gradient(30deg, green 50%, transparent 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}
.adminButtonGreen:hover {
  background-position: 100%;
  color: green;
}